<template>
    <div>
        <v-icon small class="mr-2" @click="updateSetting"
                v-if="checkUserPermission('EDIT')">
            mdi-pencil
        </v-icon>
        <!-- <v-icon color="red" small
                @click="dialogDelete = true"
                :disabled="dataDelete.length > 0"
                v-if="checkUserPermission('DELETE')">
            mdi-delete
        </v-icon>
        <dialog-delete :darkmode="darkmode"
                       :dialog-delete="dialogDelete"
                       v-on:closeDelete="cancelDelete"
                       v-on:deleteItemConfirm="deleteItem" /> -->
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    // import DialogDelete from "../../../../components/shared/notify/DialogDelete";

    export default {
        // components: { DialogDelete },
        props: ['darkmode', "item", "params", "userPermission"],
        data() {
            return {
                // dialogDelete: false,
            };
        },
        methods: {
            async updateSetting() {
                await this.fetchCurrentSetting(this.item);
                await this.fetchPopUp(true);
            },
            ...mapActions("$_promotion", ["fetchPopUp", "fetchListSetting", "deleteSetting", "fetchCurrentSetting"]),
        },

    };
</script>

<style lang="scss" scoped>
</style>
